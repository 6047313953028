<!-- <empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay> -->
<div>
    <div class="headerEditNew">
        <h2 class="titleNew">{{'PROFILE.TITLE' | translate}}</h2>
        <h5>({{tenantUser}})</h5>
        <h5>({{currentEmail}})</h5>
        <h4>{{name_roles}}</h4>
    </div>
    <div class="headerImg"> <img class="imgSize" [src]="url"></div>
</div>

<form class="container" [formGroup]="userForm">
    <div>
        <mat-form-field class="formUserMan">
            <input matInput [errorStateMatcher]="matcher" formControlName="firstname"
                placeholder="{{'PROFILE.FIRSTNAME' | translate}}" name="firstname" required
                [ngClass]="{ 'is-invalid': f.firstname.errors }" mt-autofocus>
            <mat-error *ngIf="userForm.controls.firstname.hasError('required')">
                {{'PROFILE.REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="userForm.controls.firstname.hasError('pattern')">
                {{'PROFILE.INVALID' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="formUserMan">
            <input matInput [errorStateMatcher]="matcher" formControlName="lastname"
                placeholder="{{'PROFILE.LASTNAME' | translate}}" name="lastname" required
                [ngClass]="{ 'is-invalid': f.lastname.errors }" mt-autofocus>
            <mat-error *ngIf="userForm.controls.lastname.hasError('required')">
                {{'PROFILE.REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="userForm.controls.lastname.hasError('pattern')">
                {{'PROFILE.INVALID' | translate}}</mat-error>
        </mat-form-field>

        <button mat-button color="primary" (click)="changePassword()"> {{'PROFILE.RESET' | translate}}</button>
    </div>
</form>
<p></p>
<div mat-dialog-actions class="buttonUserMan" align="end">
    <button class="buttonClose" mat-raised-button mat-dialog-close>
        {{'PROFILE.CANCEL' | translate}}</button>
    <button class="buttonConfirm" [disabled]="!userForm?.valid" mat-raised-button color="primary" (click)="onSubmit()">
        {{'PROFILE.UPDATE' | translate}}</button>
</div>

<empusa-mat-spinner-overlay *ngIf="loading" overlay="true"></empusa-mat-spinner-overlay>