<div *ngIf="isPageLoading" class="animated fadeIn page-before-html-loaded">
    <div class="preloading center-screen" *ngIf="isPageLoading"></div>
</div>

<div *ngIf="!isPageLoading" class="background">
<div  class="biglogo animated lightSpeedIn"></div>
<form *ngIf="!isPageLoading" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <mat-card class="logocard animated fadeInUp">
        <div class="logintitle">{{'LOGIN.TITLE' | translate}}</div>
        <mat-card-content>
            <table cellspacing="0">
                <tr>
                    <td>
                        <mat-form-field>
                            <input matInput formControlName="username"
                                placeholder="{{'LOGIN.LABEL.USERNAME' | translate}}" name="username"
                                [ngClass]="{ 'is-invalid': submitted && f.username.errors }" mt-autofocus>
                        </mat-form-field>
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required">{{'LOGIN.REQUIRED.USERNAME' | translate}}</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-form-field>
                            <input matInput formControlName="password"
                                placeholder="{{'LOGIN.LABEL.PASSWORD' | translate}}" type="password" name="password"
                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                        </mat-form-field>
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">{{'LOGIN.REQUIRED.PASSWORD' | translate}}</div>
                        </div>
                    </td>
                </tr>
            </table>
            <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>
        </mat-card-content>
        <mat-card-actions>
            <span class="version">v1.0</span>
            <button [disabled]="loading" type="submit" class="loginbutton" mat-raised-button (click)="onSubmit()"
                color="primary">{{'LOGIN.BUTTON.SIGNIN' | translate}}</button>
            <span class="forgot">{{'LOGIN.FORGOTPASSWORD' | translate}}</span>
        </mat-card-actions>
    </mat-card>
</form>
</div>