<h2 style="font-weight: bold" mat-dialog-title>Terms of Use</h2>
<mat-dialog-content class="mat-typography">
    <h3 style="font-weight: bold">BOUSTEAD PROJECTS SMART BUILDING INFRASTRUCTURE ("SYSTEM")</h3>
    <p>
        PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY BEFORE ACCESSING
        AND PROCEEDING TO USE THE SYSTEM. BY ACCESSING AND PROCEEDING TO USE TO
        SYSTEM, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS BELOW AND ANY
        OPERATING RULES AND POLICIES THAT MAY BE PUBLISHED FROM TIME TO TIME BY US.
    </p>
    <p style="font-style: italic;">
        Any reference to "Boustead Projects", "us", "we" or "our" shall mean Boustead Projects Limited and/or
        its related corporations and affiliates, whether collectively or singularly as the context may require.

    </p>
    <h3 style="font-weight: bold">
        Your Account
    </h3>
    <p style="text-decoration: underline;"> Account Registration </p>
    <p>To access and use the System, you are required to register an Account with the System whereby you
        may choose a login or user identification ("UserID") and a password ("Password") for your account
        ("Account"). You agree that the information submitted by you for the registration of your Account is
        accurate and complete.</p>
    <p style="text-decoration: underline;">UserID and Password</p>
    <p>You are solely responsible for maintaining the confidentiality of your UserID and Password and for any
        disclosure or unauthorised use thereof. Any use and/or access to the System referable to your UserID
        and Password shall be deemed to be use and/or access to the System by you. You agree that you are
        fully responsible for all activities that occur under your Account, including any integration or any other
        use of third party products or services (and associated disclosure of data) in connection with the System
        and we shall not be responsible or liable for any loss caused to or damage incurred or suffered by you
        or any person by reason of or arising from or as a consequence of any use of your Account, the UserID
        and/or Password. If you believe the security of your UserID and/or Password has been compromised,
        you shall promptly notify us in writing.</p>
    <p style="text-decoration: underline;">Personal Data</p>
    <p>For the purpose of registration of your Account, we would collect certain personal data (as defined in
        the Personal Data Protection Act 2012 ("PDPA")) from you, which includes, your name, email address,
        facial image, etc.</p>
    <p>In general, subject to any applicable law, we will use and disclose your personal data for the following
        purposes:</p>
    <ol>
        <li>process the registration of your Account;</li>
        <li>provide customer support (including but not limited to responding to queries, feedback and
            complaints;</li>
        <li>maintaining the security of the building;</li>
        <li>maintain your Account with us;</li>
        <li>management and operation of the System;</li>
        <li>administration of your Account and/or the System;</li>
        <li>where applicable, perform financial transactions, such as payments;</li>
        <li>communicate with you on any matter relating to your Account and the System, changes to our
            policies, terms and conditions and other administrative information;</li>
        <li>comply with applicable rules, laws and regulations, codes, practices or guidelines;</li>
        <li>any other purposes related to any of the above.</li>
    </ol>
    <p>
        Your personal data may be disclosed to the extent necessary to contractors and/or service providers
        engaged by us to maintain and operation the System.
    </p>

    <p>If you provide any personal data of a third party to us, you represent and warrant that the collection,
        use and disclosure of those personal data to us, as well as the further processing of those personal
        data for the purposes set out above is lawful.</p>
    <h3>Your Conduct</h3>
    <p>You shall comply fully with all directions and/or instructions issued by us from time to time in respect of
        the use of the System and you shall abide by all applicable laws and regulations in the use of the
        System.
        You shall not use the System for any illegal purposes.</p>
    <h3 style="font-weight: bold">Links to Third-Party Websites</h3>
    <p>Any third-parties’ websites linked to or from this Website are independently maintained by such thirdparties and
        are not under the control and/or supervision of Boustead Projects. Use of these linked
        websites shall be subject to the terms and conditions stipulated by the operators of such websites.
        Boustead Projects will not be responsible for any damage or loss caused in connection with the use of
        any linked websites. Access to any of the linked websites shall be at your own risk.
        Nothing contained in this System shall be interpreted as an endorsement and/or recommendation by
        Boustead Projects of the contents, products and/or services appearing on such linked websites. Nothing
        contained herein shall be deemed to constitute a partnership or the like between the operators of the
        linked websites and Boustead Projects.</p>
    <h3 style="font-weight: bold">Limitation of Liability</h3>
    <p>Except to the extent prohibited by applicable laws, Boustead Projects makes no representation and
        warranty, either expressed or implied, statutory or otherwise, including representations or warranties
        with respect to the accuracy, completeness, fitness for a particular purpose, non-infringement of thirdparties’
        rights, reliability and/or safety of the contents of this System, and any representations and
        warranties relating thereto are expressly disclaimed.</p>
    <p>
        Boustead Projects may alter and/or change the contents of this System, or may discontinue or suspend
        the services provided through this System at any time, without prior notice. Boustead Projects shall not
        be liable for any damage or loss arising out of your access or use, or inability to access or use this
        System. Boustead Projects reserves the right to deny access to this System to anyone at anytime.
    </p>
    <p>Boustead Projects assumes no responsibility for problems including but not limited to, incorrect
        information on this System, or for any problems resulting from the downloading of data or malicious
        tampering with the content of data on this System by third-parties. Information on this System may
        contain technical inaccuracies or typographical errors.</p>
    <h3 style="font-weight: bold">Applicable Law and Jurisdiction</h3>
    <p>The Terms of Use of this System is governed by and construed in accordance with the laws of the
        Republic of Singapore. The courts of the Republic of Singapore will have exclusive jurisdiction in the
        case of any dispute.</p>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>