import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User, AuthenticationService } from '@empusa/empusa-core';
import { AddUserDTO } from './dto/adduser';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {

  url: string = this.environment.urlbase + 'usermanagement/services/';
  constructor(private http: HttpClient, @Inject('environment') private environment, private auth: AuthenticationService) {
  }

  updateUser(user: User) {
    let body = JSON.stringify(new AddUserDTO(user, this.auth.getCurrentUser().sites[0].dn));
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.put(this.url + 'users/user/' + user.uid, body, { headers }).pipe(map(resp => resp));
  }

  changePassword(email) {
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.put(this.url + 'user/resetpwd/' + email,null).pipe(map(resp => <any>resp));
  }

  getUser(uid: string): any {
    return this.http.get(this.url + 'users/user/' + uid).pipe(
      map(data => (<any>data).userInfo)
    );
  }

  getPhoto(user: User) {
    let pictureURL = this.url + 'users/photo/' + user.uid;
    return this.http.get(pictureURL, {
      responseType: 'arraybuffer'
    });
  }

  getRoles(dn: string){
    return this.http.get(this.url+'roles/user/'+dn).pipe(
      map(data => data)
    );
  }
}
