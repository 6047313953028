import { Injectable } from '@angular/core';
import { AuthenticationService, User } from '@empusa/empusa-core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class CustomAuthService extends AuthenticationService {

    constructor(httpClient: HttpClient, private router: Router) {
        super(httpClient, environment);
    }

    getCurrentUserName(): string {
        return JSON.parse(localStorage.getItem('curentLogin')).username;
    }

    getCurrentUserMail(): string {
        return JSON.parse(localStorage.getItem('curentLogin')).mail;
    }

    _logout() {
        localStorage.removeItem('curentLogin');

        //redirect to login
        this.router.navigateByUrl('/login');
        return true; //:D
    }

}
