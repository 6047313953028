export const database = {
    microappSecurityTree: {
        'exampleMicroApp': {
            'exampleFunctionality1': {
                'ROL1': true,
                'ROL2': true
            },
            'exampleFunctionality2': {
                'ROL2': true
            }
        },
        'faultreport': {
            'access': {
                'ADMIN': true
            }
        },

    },
    users: [
        {
            uid: "1",
            mail: "john2.doe2@boustead.sh",
            userPassword: "admin",
            givenName: "fakeJohn",
            surname: "Doe",
            sites: [
                {
                    uid: "1",
                    name: "Torre Triana",
                    description: "Torre Triana en Sevilla",
                    tenants: [
                        {
                            uid: "1",
                            name: "tenant 1",
                            description: "Test tenant 1"
                        }
                    ]
                }
            ],
            roles: [
                {
                    uid: "ADMIN",
                    name: "ADMIN",
                }
            ]
        }
    ],
    loggedUser: null
}

database.loggedUser = database.users[0];
