export const locale = {
    "lang": "en",
    "data": {
        "LOGIN": {
            "TITLE": "Login",
            "LABEL.USERNAME": "Username",
            "LABEL.PASSWORD": "Password",
            "BUTTON.SIGNIN": "Sign in",
            "FORGOTPASSWORD": "Forgot password?",
            "REQUIRED.USERNAME": "Username is required!",
            "REQUIRED.PASSWORD": "Password is required!"
        },
        "HOME": {
            "SELECT-SITE": "Selected Building",
            "MENU.SETTINGS": "Settings",
            "MENU.LANGUAGE": "Language",
            "MENU.NOTIFICATIONS": "Notifications",
            "MENU.LOGOUT": "Logout",
            "MENU.HOME": "Home",
            "MENU.LANGUAGE.SPANISH": "Español",
            "MENU.LANGUAGE.ENGLISH": "English"
        },
        "DIALOG": {
            "QUESTION.OK": "Ok",
            "QUESTION.CANCEL": "Cancel",
            "DELETE.TITLE": "Delete",
            "DELETE.QUESTION": "Are you sure to delete the record?",
            "DELETE.ERROR": "An error has occurred, please, contact with the technical service ",
            "DELETE.CONFIRMATION": "The record was removed succesfully.",
            "DELETE.OK": "OK",
            "DELETE.TENANT.USER": "This tenant can't be deleted while it contains users",
            "DELETE.ERROR.TITLE": "ERROR",
            "DELETE.NOTFOUND": "This record is not found",
            "DELETE.NOT": "NOT FOUND",
        },
        "MATERIAL": {
            "TABLE.ITEMS_PER_PAGE_LABEL": "Items/page",
            "TABLE.NEXT_PAGE_LABEL": "Next",
            "TABLE.PREVIOUS_PAGE_LABEL": "Previous",
            "TABLE.FIRST_PAGE_LABEL": "First",
            "TABLE.LAST_PAGE_LABEL": "Last",
            "TABLE.RANGE_PAGE_LABEL_1": "0 of {{length}}",
            "TABLE.RANGE_PAGE_LABEL_2": "{{startIndex}} - {{endIndex}} of {{length}}"
        },
        "PROFILE":{
            "TITLE": "User Profile",
            "REQUIRED": "This is a required field",
            "INVALID": "The field has an invalid value",
            "FIRSTNAME": "First Name",
            "LASTNAME": "Last Name",
            "EMAIL": "Email",
            "PASSWORD": "New Password",
            "CONFIRM": "Confirm New Password",
            "NOTCONFIRM": " The confirmation does not match the password",
            "CANCEL": "Cancel",
            "UPDATE": "Update Profile",
            "RESET": "Reset Password"
        }
    }
}