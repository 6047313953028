import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, User } from '@empusa/empusa-core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { database } from './fake.database';


@Injectable({ providedIn: 'root' })
export class FakeAuthService extends AuthenticationService {

    constructor(httpClient: HttpClient, private router: Router) {
        super(httpClient, environment);
    }

    getCurrentUserName(): string {
        return database.loggedUser.userName;
    }

    getCurrentUserMail(): string {
        return database.loggedUser.mail;
    }

    _logout() {
        //redirect to login
        this.router.navigateByUrl('/login');
        return true; //:D
    }

}
