<mat-drawer-container class="container" [hasBackdrop]="false">
    <mat-drawer #drawer [mode]="'side'">

        <div class="head-drawer-title">
            <mat-icon class="back" (click)="drawer.toggle()">keyboard_backspace</mat-icon>
            <div class="logo"></div>
            <div *ngIf="site" class="site">{{site.name}}</div>
        </div>

        <nav style="min-height: 72%;">
            <ul>
                <li *ngFor="let module of modules; let i = index" routerLinkActive="active" [routerLink]="['/'+module.path]"
                    class="animated fadeInUp delay1">
                    <a>
                        <mat-icon>{{module.icon}}</mat-icon>
                        <span>{{module.description | translate}}</span>
                    </a>
                    <mat-icon class="arrow_box">keyboard_arrow_right</mat-icon>
                </li>
                <li  *ngIf="isAdmin && incluideGrafana"
                class="animated fadeInUp delay1">
                <a href="{{bmsGrafana}}" target="_blank">
                    <mat-icon>trending_up</mat-icon>
                    <span>BMS Dashboards</span>

                </a>
                </li>
            </ul>
        </nav>
        
        <div class="divider"></div>
        <div>
            <a style="width: 100%; text-align: start;" mat-button href="https://boustead.sg/privacy-policy" target="_blank">Privacy Policy</a>
            <button style="width: 100%; text-align: start;" mat-button (click)="openDialog()">Terms of Use</button>
        </div>
        <span class="copyright">© All Rights Reserved</span>


    </mat-drawer>
    <mat-drawer-content>

        <div class="head">
            <!--left head-->
            <mat-icon class="menubutton" (click)="drawer.toggle()">menu</mat-icon>
            <div class="logo-right"></div>

            <!--right head-->
            <mat-icon class="morebutton" [matMenuTriggerFor]="menu">more_vert</mat-icon>
            <mat-menu #menu="matMenu">
                <!--
                <button mat-menu-item>
                    <mat-icon>settings</mat-icon>
                    <span>{{'HOME.MENU.SETTINGS' | translate}}</span>
                </button>
                -->
                 
                <button (click)="profileUser()" mat-menu-item>
                    <mat-icon>account_box</mat-icon>
                    <span>{{'PROFILE.TITLE' | translate}}</span>
                </button>
               
                <button mat-menu-item [matMenuTriggerFor]="languages">
                    <mat-icon>language</mat-icon>
                    <span>{{'HOME.MENU.LANGUAGE' | translate}}</span>
                </button>
                <!-- pending functionality
                <button mat-menu-item>
                    <mat-icon>notifications_off</mat-icon>
                    <span>{{'HOME.MENU.NOTIFICATIONS' | translate}}</span>
                </button>
                -->
                <mat-divider></mat-divider>
                <button (click)="logout()" mat-menu-item>
                    <mat-icon>exit_to_app</mat-icon>
                    <span>{{'HOME.MENU.LOGOUT' | translate}}</span>
                </button>
            </mat-menu>

           <mat-menu #languages="matMenu" class="language-menu">
            <!-- <button mat-menu-item (click)="onChangeLanguage('es')" [class.selected]="currentLang=='es'">
                <mat-icon svgIcon="flag-es"></mat-icon>
                <span>{{'HOME.MENU.LANGUAGE.SPANISH' | translate}}</span>
            </button> -->
            <button mat-menu-item (click)="onChangeLanguage('en')" [class.selected]="currentLang=='en'">
                <mat-icon svgIcon="flag-en"></mat-icon>
                <span>{{'HOME.MENU.LANGUAGE.ENGLISH' | translate}}</span>
            </button>
        </mat-menu>


            <img #userPicture class="userpicture animated delay-2s fadeIn" src="assets/img/default_user.png">
            <!-- pending functionality
            <mat-icon class="notifications animated tada" matBadge="15" matBadgeColor="accent">mail</mat-icon>
            -->

            <div *ngIf="sites && sites.length==1" class="site-one">
                <mat-icon>business</mat-icon>
                <span>{{sites[0].name}}</span>
            </div>

            <mat-form-field *ngIf="sites && sites.length>1" class="site-selection">
                <mat-label>{{ 'HOME.SELECT-SITE' | translate}}</mat-label>
                <mat-select (selectionChange)="onSiteChange($event)" [formControl]="selectedSiteControl">
                    <mat-option *ngFor="let site of sites; let i=index" [value]="site.uid">
                        {{site.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div class="container">
            <router-outlet></router-outlet>
        </div>

    </mat-drawer-content>
</mat-drawer-container>
