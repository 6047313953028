export const environment = {
  production: true,
  urlbase: '/',
  auth: 'keycloak',
  backend: 'real',
  grafana: 'http://grafana.alice.boustead.getronicssmartspaces.com/grafana',
  keycloak: {
    'url': '/auth',
    'realm': 'Alice',
    'clientId': 'empusa',
  },
  //the currency used
  currency: 'S$',
  show_acess_group: false
};
