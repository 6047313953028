import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/client/login.service';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.css'],
})
export class LoginPage implements OnInit, AfterViewChecked {

  submitted: boolean = false;
  loading: boolean = false;
  loginForm: FormGroup;
  /** the first html page loading flag (the animation will be smoother if we wait the html to being loaded completelly) */
  isPageLoading: boolean = true;
  showSpinner: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService) {

    let self = this;
    if (document.readyState === "complete") {
      this.isPageLoading = false;
    } else {
      window.onload = function () {
        self.isPageLoading = false;
      }
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // reset login status
    this.loginService.logout();
  }

  ngAfterViewChecked() {
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.loginService.login(this.f.username.value, this.f.password.value).pipe(first()).subscribe(
      data => {
        this.router.navigateByUrl('');
      },
      error => {
        this.loading = false;
      }
    );
  }
}
