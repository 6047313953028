import { NgModule } from '@angular/core';
import { MicroappEpsModule } from 'microapp-eps';
import { MicroappFacilitiesbookingModule } from 'microapp-facilitiesbooking';
import { MicroappFaultreportModule } from 'microapp-faultreport';
import { MicroappFittingoutModule } from 'microapp-fittingout';
import { MicroappHomeModule } from 'microapp-home';
import { MicroappHvacModule } from 'microapp-hvac';
import { MicroappNotificationsModule } from 'microapp-notifications';
import { MicroappQuizModule } from 'microapp-quiz';
import { MicroappTariffModule } from 'microapp-tariff';
import { MicroappUsermanagementModule } from 'microapp-usermanagement';
import { MicroappVmsModule } from 'microapp-vms';

@NgModule({
  imports: [
  MicroappEpsModule,MicroappFacilitiesbookingModule,MicroappFaultreportModule,MicroappFittingoutModule,MicroappHomeModule,MicroappHvacModule,MicroappNotificationsModule,MicroappQuizModule,MicroappTariffModule,MicroappUsermanagementModule,MicroappVmsModule,
  ],
  exports: [], providers:[]
})
export class ExternalEmpusaModule { }
