import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule, PreloadAllModules, Router, Routes, NoPreloading } from '@angular/router';
import { FakeAuthGuard } from './fake/fake.auth.guard';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HomePage } from './pages/home/home.page';
import { LoginPage } from './pages/login/login.page';
import { environment } from 'src/environments/environment';
import { KeycloakService } from './auth/keycloak/keycloak.auth.service';
import { KeycloakJwtInterceptor } from './auth/keycloak/keycloak.jwt.interceptor';
import { KeycloakAuthGuard } from './auth/keycloak/keycloak.guard';
import { CustomAuthGuard } from './auth/custom/custom.guard';
import { CustomAuthService } from './auth/custom/custom.auth.service';
import { CustomJwtInterceptor } from './auth/custom/custom.jwt.interceptor';
import { CustomErrorInterceptor } from './auth/custom/custom.error.interceptor';
import { FakeAuthService } from './fake/fake.auth.service';
import { FakeBackendProvider } from './fake/fake.backend';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomMaterialModule } from './material.module';
import { EmpusaMicroApp, TranslationLoaderService, AuthenticationService } from '@empusa/empusa-core';
import { ExternalEmpusaModule } from './external.module';
import { locale as english } from './translations/translation.en';
import { locale as spanish } from './translations/translation.es';

/****************************************************************/
/*******************     EMPUSA REGISTER      *******************/
/** empusa will be the "class" where everything starts */
function empusa(module: EmpusaMicroApp) {
  (<any>window).empusa.register(module);
}
(<any>window).empusa = empusa;
empusa.register = function (module: EmpusaMicroApp) {
  if (!(<any>window).empusa.modules) {
    (<any>window).empusa.modules = [];
  }
  (<any>window).empusa.modules.push(module);
}


/****************************************************************/
/*******************     TRANSLATE SERVICE    *******************/
//import { FakeAuthService } from './fake/fake.auth.service';
// registrar los locales con el nombre que quieras utilizar a la hora de proveer
// import locales
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { SecurePipe } from './auth/secure.pipe';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from './translations/paginator.i18n';
registerLocaleData(localeEn, 'en')
// registerLocaleData(localeEs, 'es');
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


/****************************************************************/
/*******************      AUTH SERVICES       *******************/
//defining the login/auth services, based onconfiguration
let auth_config = {
  //defining the auth services
  auth: KeycloakService,
  //defining the authorization interceptors
  interceptors: [
    {
      provide: APP_INITIALIZER,
      useFactory: kcFactory,
      deps: [KeycloakService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: KeycloakJwtInterceptor, multi: true }
  ],
  //defining the guard routing
  guard: KeycloakAuthGuard
}

//all these based on configuration
if (environment.auth === 'keycloak') {
  //keycloak configuration
  auth_config.guard = KeycloakAuthGuard;
  auth_config.auth = KeycloakService;
  auth_config.interceptors = [
    {
      provide: APP_INITIALIZER,
      useFactory: kcFactory,
      deps: [KeycloakService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: KeycloakJwtInterceptor, multi: true }
  ];

} else if (environment.auth === 'custom') {
  //custom login
  (<any>auth_config).guard = CustomAuthGuard;
  (<any>auth_config).auth = CustomAuthService;
  (<any>auth_config).interceptors = [
    { provide: HTTP_INTERCEPTORS, useClass: CustomJwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CustomErrorInterceptor, multi: true },
  ];
} else if (environment.auth === 'fake') {
  (<any>auth_config).guard = FakeAuthGuard;
  (<any>auth_config).auth = FakeAuthService;
  auth_config.interceptors = [];//no interceptors
}

/** KeyCloak Factory */
export function kcFactory(keycloakService: KeycloakService) {
  return () => keycloakService.init();
}

/****************************************************************/
/*******************    ROOT CONFIGURATION    *******************/
const basicRootConfiguration: Routes = [
  { path: 'login', component: LoginPage },
  {
    path: '', component: HomePage,
    canActivate: [auth_config.guard],
  }];

/****************************************************************/
/*******************       FAKE BACKEND       *******************/
let fakeBackend = [];
if (environment.backend === 'fake') {
  fakeBackend.push(FakeBackendProvider);
}

/****************************************************************/
/*******************     MODULE DEFINITION    *******************/
@NgModule({
  declarations: [
    AppComponent,
    HomePage,
    LoginPage,
    SecurePipe
  ],
  imports: [
    BrowserModule,
    //we statically load the external microapps interfaces modules
    ExternalEmpusaModule,
    CustomMaterialModule,
    ReactiveFormsModule,
    AppRoutingModule,
    //routing
    RouterModule.forRoot(basicRootConfiguration,
      {
        //enable in case you are having problems with routing to show debug traces
        enableTracing: false,
        useHash: true
      }),
    //translate
    HttpClientModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: 'environment', useValue: environment },
    auth_config.interceptors,
    auth_config.guard,
    { provide: AuthenticationService, useClass: auth_config.auth },
    fakeBackend,
    {
      provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private router: Router, private translationLoader: TranslationLoaderService) {
    //loading empusa core translations
    this.translationLoader.loadTranslations(english);

    //loading microapps routes
    basicRootConfiguration[1].children = [];
    if ((<any>window).empusa.modules) {
      (<any>window).empusa.modules.forEach(element => {
        //adding the microapps routes dynamically!
        basicRootConfiguration[1].children.push(
          {
            path: element.path,
            canActivate: [auth_config.guard],
            canActivateChild: [auth_config.guard],
            //we lazy load the external microapps fat modules
            loadChildren: () => element.page
          });
      });
    }
    router.resetConfig(basicRootConfiguration);
  }

}