import { User } from '@empusa/empusa-core';
import { AccessRigths } from '@empusa/empusa-core/auth/accessRights';
import { Floor } from '@empusa/empusa-core/auth/floor';

export class AddUserDTO {
    buildingDN: string;
    groupsDN: string[];
    rolesDN: string[];
    givenName: string;
    surname: string;
    mail: string;
    password: string;
    photo: string;
    floor: Floor;
    accessRights: AccessRigths;
    default_visitor_host: boolean;

    constructor(user: User, buildingDN: string) {
        this.mail = user.mail;
        this.givenName = user.givenName;
        this.surname = user.surname;
        this.password = user.userPassword;
        this.accessRights = user.accessRights;
        this.default_visitor_host = user.default_visitor_host;
        this.floor =user.floor;
        this.rolesDN = [];
        this.groupsDN = [];
        this.buildingDN = buildingDN;
        if (user['photo']) {
            this.photo = user['photo'];
        }
        user.roles.forEach(element => {
            this.rolesDN.push(element.dn);
        });
        // user.sites[0].tenants.forEach(element => {
        //     this.groupsDN.push(element.dn);
        // });
        this.groupsDN.push(user.sites[0].tenants[0].dn);
    }
}


