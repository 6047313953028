import { Injectable } from '@angular/core';
import { AuthenticationService } from '@empusa/empusa-core';
import { environment } from 'src/environments/environment';

declare var Keycloak: any;

@Injectable({
  providedIn: 'root'
})
export class KeycloakService extends AuthenticationService {
  private static keycloakAuth: any;

  init(): Promise<any> {
    let self = this;
    return new Promise((resolve, reject) => {
      //This is a BASIC example of keycloak connection
      //don't forget to create a realm called as the realm name setted (see environment.keycloak)
      //don't forget to create a client called as the clientId setted (see environment.keycloak)
      //don't forget to set a * as "Valid redirects URIs" and "Web Origins", in the keycloak administration page, in case you want to forget CORS problems
      KeycloakService.keycloakAuth = new Keycloak(environment.keycloak);
      KeycloakService.keycloakAuth.init({ onLoad: 'login-required' })
        .success((authenticated) => {
          resolve();
        })
        .error(() => {
          reject();
        });
      KeycloakService.keycloakAuth.onTokenExpired = () => {
        self.updateToken();
      }

    });

  }

  getKeycloak() {
    return KeycloakService.keycloakAuth;
  }

  getToken(): string {
    return KeycloakService.keycloakAuth.token;
  }

  getCurrentUserName(): string {
    return KeycloakService.keycloakAuth.tokenParsed.preferred_username;
  }

  getCurrentUserMail():string{
    return KeycloakService.keycloakAuth.tokenParsed.email;
  }

  /**
   * Check if user is logged in.
   * @returns A boolean that indicates if the user is logged in.
   */
  async isLoggedIn(): Promise<boolean> {
    try {
      if (!KeycloakService.keycloakAuth.authenticated) {
        return false;
      }
      await this.updateToken(20);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * If the token expires within minValidity seconds the token is refreshed. If the
   * session status iframe is enabled, the session status is also checked.
   * Returns a promise telling if the token was refreshed or not. If the session is not active
   * anymore, the promise is rejected.
   *
   * @param minValidity
   * Seconds left. (minValidity is optional, if not specified 5 is used)
   * @returns
   * Promise with a boolean indicating if the token was succesfully updated.
   */
  updateToken(minValidity: number = 5): Promise<boolean> {
    return new Promise(async (resolve, reject) => {

      if (!KeycloakService.keycloakAuth) {
        reject('Keycloak Angular library is not initialized.');
        return;
      }

      KeycloakService.keycloakAuth
        .updateToken(minValidity)
        .success(refreshed => {
          resolve(refreshed);
        })
        .error(() =>
          reject('Failed to refresh the token, or the session is expired')
        );
    });
  }

  /**
  * Returns true if the token has less than minValidity seconds left before
  * it expires.
  *
  * @param minValidity
  * Seconds left. (minValidity) is optional. Default value is 0.
  * @returns
  * Boolean indicating if the token is expired.
  */
  isTokenExpired(minValidity: number = 0): boolean {
    return KeycloakService.keycloakAuth.isTokenExpired(minValidity);
  }

  _logout() {
    this.getKeycloak().logout();
  }

}
