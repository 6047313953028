import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from '@empusa/empusa-core';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class LoginService {

    constructor(private http: HttpClient) {
    }

    logout() {
        localStorage.removeItem('curentLogin');
    }


    /**
     * @description perform a login against the backend
     * @param username the username to send
     * @param password the password to send
     */
    login(username: string, password: string) {
        let hpassword = password; //TODO encrypt password
        return this.http.get(`${environment.urlbase}login?username=${username}&password=${hpassword}`).pipe(
            map(logindata => {
                localStorage.setItem('curentLogin', JSON.stringify(logindata));
                return logindata;
            })
        );
    }
}
