import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSpinnerOverlayComponent } from './pages/mat-spinner-overlay/macroapp.mat-spinner-overlay.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatFormFieldModule } from "@angular/material/form-field";
import { ReactiveFormsModule } from "@angular/forms";
import { ProfileUserComponent } from "./pages/profile/profile.page";
import { TranslateModule } from "@ngx-translate/core";
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxMaskModule } from "ngx-mask";
import { MatTableExporterModule } from 'mat-table-exporter';
import {MatCarouselModule} from "@ngbmodule/material-carousel";
import { DialogContentTermsDialog } from "./pages/home/terms/dialog-content-terms-dialog";


@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatSidenavModule,
        MatSelectModule,
        MatBadgeModule,
        MatMenuModule,
        MatDividerModule,
        MatTooltipModule,
        MatToolbarModule,
        MatTableModule,
        MatSortModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatDividerModule,
        MatListModule,
        MatStepperModule,
        MatDatepickerModule,
        MatChipsModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        MatPaginatorModule,
        MatExpansionModule,
        ReactiveFormsModule,
        TranslateModule,
        AngularEditorModule,
        MatTableExporterModule,
        MatCarouselModule,
    ],
    exports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatSidenavModule,
        MatSelectModule,
        MatBadgeModule,
        MatMenuModule,
        MatTooltipModule,
        MatDividerModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        NgxMaskModule,
    ], 
    declarations: [
        ProfileUserComponent,
        MatSpinnerOverlayComponent,
        DialogContentTermsDialog
    ],
    providers: [
        
        MatSnackBar
        
    ]
})
export class CustomMaterialModule { }