
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { KeycloakService } from './keycloak.auth.service';
import { Injectable } from '@angular/core';

@Injectable()
export class KeycloakAuthGuard implements CanActivate {
    /**
     * Indicates if the user is authenticated or not.
     */
    protected authenticated: boolean;

    constructor(protected router: Router, protected keycloakAngular: KeycloakService) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
        return this.canActivate(childRoute, state);
    }


    /**
     * CanActivate checks if the user is logged in and get the full list of roles (REALM + CLIENT)
     * of the logged user. This values are set to authenticated and roles params.
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.authenticated = await this.keycloakAngular.isLoggedIn();

                const result = await this.isAccessAllowed(route, state);
                resolve(result);
            } catch (error) {
                reject('An error happened during access validation. Details:' + error);
            }
        });
    }

    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        //TODO?
        return true;
    }
}