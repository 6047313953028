import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthenticationService, Rol, User } from "@empusa/empusa-core";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription } from "rxjs";
import { ProfileService } from "../../client/profile.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, takeUntil } from "rxjs/operators";
import { ErrorStateMatcher } from "@angular/material/core";
import { NgIf } from "@angular/common";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.page.html',
    styleUrls: ['./profile.page.css']
})


export class ProfileUserComponent implements OnInit, OnDestroy {

    destroy$: Subject<null> = new Subject();

    currentUser: User;

    /** form to validate */
    public userForm: FormGroup;
    public passwordGroup: FormGroup;

    /**the user being edited */
    editUser: User = new User();
    roles: Rol[] = [];
    // tenants: Tenant[] = [];
    matcher = new MyErrorStateMatcher();
    selectSite: number = 0
    selectTenant: number = 0
    viewSite: boolean = false;
    viewTenant: boolean = false;
    fileToUpload: File;
    kittyImagePreview: string | ArrayBuffer;
    isEdit: boolean;
    loading: boolean;
    url: any;
    viewPassword: boolean = false;
    accessList = [];
    fireTenant: string;
    isSuperAdmin: boolean;
    editSuperAdmin: boolean = false;
    currentEmail: string;
    name_roles: string[] = [];
    tenantUser: string;
    password2: string;
    notEqual: boolean;

    @ViewChild('userPicture') userPictureImg;

    constructor(

        private profileService: ProfileService,
        public dialogRef: MatDialogRef<ProfileUserComponent>,
        private snackBar: MatSnackBar, private translate: TranslateService,
        private formBuilder: FormBuilder,
        private auth: AuthenticationService,
        @Inject(MAT_DIALOG_DATA) public ticket_id: string) {

        this.userForm = this.formBuilder.group({
            photo: [],
            email: ['', [Validators.required, Validators.email]],
            password: [''],
            confirm: [''],
            firstname: ['', [Validators.required, Validators.pattern('^[a-zA-Z\u00f1\u00d1-]+(?: [a-zA-Z\u00f1\u00d1-]+)*$')]],
            lastname: ['', [Validators.required, Validators.pattern('^[a-zA-Z\u00f1\u00d1-]+(?: [a-zA-Z\u00f1\u00d1-]+)*$')]],
            rol: ['', Validators.required],
            site: [],
            access: [''],
            tenant: [''],
            floor: [''],
            host: ['']
        });

        this.url = "assets/img/default_user.png";
        this.loading = true;
        this.profileService.getUser(ticket_id).subscribe(resp => {
            this.loading = false;
            this.currentEmail = resp.mail;
            this.currentUser = resp;
            this.profileService.getPhoto(resp).subscribe(data => {
                this.loading = false;
                this.url = 'data:image/jpeg;base64,' + arrayBufferToBase64(data);
            });

            this.currentUser.roles.forEach(rol => {
                this.roles.push(rol)
            });
            this.f.firstname.setValue(this.currentUser.givenName);
            this.f.lastname.setValue(this.currentUser.surname);
            this.f.host.setValue(this.currentUser.default_visitor_host);
            this.f.email.setValue(this.currentUser.mail);
            if (this.currentUser.roles != null) {
                let id_roles: string[] = [];
                let name_roles: string[] = [];
                for (let i = 0; i < this.currentUser.roles.length; i++) {
                    id_roles.push(this.currentUser.roles[i].uid);
                    let role_replace = (this.currentUser.roles[i].name)
                    role_replace = role_replace.replace("USER", "EMPLOYEE").replace("MANAGEMENT", "TENANT MANAGER").replace("ADMIN", "FACILITY MANAGER").replace("SUPERFACILITY MANAGER", "SUPERADMIN")
                    name_roles.push(role_replace);
                }
                this.f.rol.setValue(id_roles);
                this.name_roles = name_roles;
            }
            if (this.currentUser.accessRights != null) {
                this.f.access.setValue(this.currentUser.accessRights.AccessRightId);
            }
            this.f.floor.setValue(this.currentUser.floor.HomeFloorId);



            if (this.currentUser.sites != null) {
                this.viewSite = true;
                this.f.site.setValue(this.currentUser.sites[0].uid);
                this.tenantUser = this.currentUser.sites[0].tenants[0].name;
                if (this.currentUser.sites[0].tenants.length > 0) {
                    this.viewTenant = true;
                    // this.f.tenant.setValue(resp.sites[0].tenants[0].uid);
                }
            }
        });

    }

    // convenience getter for easy access to form fields
    get f(): any { return this.userForm.controls; }

    ngOnInit() {
        this.userForm.get('photo')
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((newValue) => {
                this.handleFileChange(newValue.files);
            });
    }

    handleFileChange([kittyImage]) {
        this.fileToUpload = kittyImage;
        const reader = new FileReader();
        reader.onload = (loadEvent) => (this.kittyImagePreview = loadEvent.target.result);
        reader.readAsDataURL(kittyImage);
    }

    ngOnDestroy() {
        this.destroy$.next(null);
    }

    setMessage(c: AbstractControl): void {
        this.password2 = '';
        if ((c.touched || c.dirty) && c.errors) {
            this.password2 = Object.keys(c.errors).map(
                key => this.validationPassword[key]).join(' ');
        }
    }

    private validationPassword = {
        required: 'Please enter your password.',
        email: 'Please enter a valid password.'
    };

    onSubmit() {
        if (this.userForm.invalid) {
            return;
        }
        this.loading = true;
        this.editUser.uid = this.currentUser.uid;
        this.editUser.userPassword = this.f.password.value;
        this.editUser.givenName = this.f.firstname.value;
        this.editUser.givenName = this.editUser.givenName.trim();
        this.editUser.surname = this.f.lastname.value;
        this.editUser.surname = this.editUser.surname.trim();
        this.editUser.mail = this.f.email.value;
        this.editUser.default_visitor_host = this.f.host.value;
        this.editUser.floor = this.f.floor.value;
        this.editUser.accessRights = this.f.access.value;
        if (typeof this.kittyImagePreview === 'string') {
            let image: string = this.kittyImagePreview;
            if (image.length > 100) {
                let n = image.indexOf(",") + 1;
                this.editUser['photo'] = image.substr(n);
            }
        }
        this.editUser.roles = this.findRolById(this.f.rol.value);
        this.editUser.sites = [];
        this.editUser.sites.push(this.currentUser.sites[0]);
        this.editUser.sites[0].tenants.push(this.currentUser.sites[0].tenants[0]);
        this.profileService.updateUser(this.editUser).subscribe(data => {
            this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.OK.MESSAGE"),
                this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.OK.TITLE"), {
                duration: 5000,
            });
            this.loading = false;
            this.dialogRef.close(this.editUser);
        }, error => {
            this.snackBar.open(this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.MESSAGE"),
                this.translate.instant("MAPP-USERMANAGEMENT.EDITUSER.ERROR.TITLE"), {
                duration: 5000,
            });
        })
    }


    findRolById(uid: string[]) {
        let rols: Rol[] = [];
        for (let i = 0; i < this.roles.length; i++) {
            let rol = this.roles[i];
            for (let j = 0; j < uid.length; j++) {
                if (rol.uid == uid[j]) {
                    rols.push(rol);
                }
            }
        }
        return rols;
    }

    ////PASSWORD

    onPasswordBlur() {
        if (this.f.password.value != this.f.confirm.value) {
            this.f.password.setErrors({ 'notSame': true })
            this.f.confirm.setErrors({ 'notSame': true })
        } else if (this.f.password.value === this.f.confirm.value) {
            this.removeError(this.f.password, 'notSame')
            this.removeError(this.f.confirm, 'notSame')
        }
    }


    removeError(control: AbstractControl, error: string) {
        const err = control.errors; // get control errors
        if (err) {
            delete err[error]; // delete your own error
            if (!Object.keys(err).length) { // if no errors left
                control.setErrors(null); // set control errors to null making it VALID
            } else {
                control.setErrors(err); // controls got other errors so set them back
            }
        }
    }

    changePassword() {
        let email = this.f.email.value;
        this.loading = true;
        this.profileService.changePassword(email).subscribe(data => {
            this.dialogRef.close();
            this.loading = false;
            this.snackBar.open(this.translate.instant(data.message),
                this.translate.instant("PROFILE.RESET"), {
                duration: 5000,
            });
        })
    }

}



/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

export function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}
