import {
    ComponentFactoryResolver,
    Injectable,
    Inject,
    ReflectiveInjector,
    ViewContainerRef
} from '@angular/core'

@Injectable()
export class ModuleInjectorService {
    factoryResolver: ComponentFactoryResolver;
    rootViewContainer: ViewContainerRef;

    constructor(@Inject(ComponentFactoryResolver) factoryResolver) {
        this.factoryResolver = factoryResolver
    }

    setRootViewContainerRef(viewContainerRef) {
        this.rootViewContainer = viewContainerRef
    }

    addDynamicComponent(module) {
        const factory = this.factoryResolver
            .resolveComponentFactory(module)
        const component = factory
            .create(this.rootViewContainer.parentInjector)
        this.rootViewContainer.insert(component.hostView)
    }
}