import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from '@empusa/empusa-core';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class PhotoService {

    constructor(private http: HttpClient) {
    }

    /**
     * @description return the photo for the user
     * @param user the suer to get the photo
     */
    getPhoto(user: User) {
        let pictureURL = `${environment.urlbase}usermanagement/services/users/photo/${user.uid}`;
        return this.http.get(pictureURL, {
            responseType: 'arraybuffer'
        });
    }
}
