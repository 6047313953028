import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KeycloakService } from './keycloak.auth.service';

@Injectable()
export class KeycloakJwtInterceptor implements HttpInterceptor {

    constructor(private service: KeycloakService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = this.service.getKeycloak().token;

        request = request.clone({
            setHeaders: { 
                Authorization: 'Bearer ' + token ,
                'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        });


        return next.handle(request);
    }
}